// src/components/Layout.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Layout.css';
import { GoGraph } from "react-icons/go";
import { FaWallet } from "react-icons/fa";
import { FaRegFileAlt } from "react-icons/fa";
import { FaChartPie } from "react-icons/fa";
import { FaRegLightbulb } from "react-icons/fa";

const Layout = ({ children }) => {
    return (
        <div className="layout-container">
            <div className="content">
                {children}
            </div>
            <nav className="navbar">
                <NavLink to="/Avisos" className={({ isActive }) => isActive ? 'nav-item active' : 'nav-item'}>
                    <div className="icon-wrapper">
                        <FaRegFileAlt size={30} />
                    </div>
                </NavLink>
                <NavLink to="/Carteiras" className={({ isActive }) => isActive ? 'nav-item active' : 'nav-item'}>
                    <div className="icon-wrapper">
                        <FaWallet size={30} />
                    </div>
                </NavLink>
                <NavLink to="/Home" className={({ isActive }) => isActive ? 'nav-item active' : 'nav-item'}>
                    <div className="icon-wrapper">
                        <GoGraph size={30} />
                    </div>
                </NavLink>
                <NavLink to="/Saques" className={({ isActive }) => isActive ? 'nav-item active' : 'nav-item'}>
                    <div className="icon-wrapper">
                        <FaChartPie size={30} />
                    </div>
                </NavLink>
                <NavLink to="/Projetos" className={({ isActive }) => isActive ? 'nav-item active' : 'nav-item'}>
                    <div className="icon-wrapper">
                        <FaRegLightbulb size={30} />
                    </div>
                </NavLink>
            </nav>
        </div>
    );
};

export default Layout;
