import React, { useState, useEffect } from 'react';
import './Saques.css';
import Layout from '../Components/Layout.js';
import { NavLink } from "react-router-dom";
import { FaUser, FaCalendarAlt, FaFileAlt } from "react-icons/fa";
import logo from "../Components/assets/logo.png";
import emailjs from 'emailjs-com'; // Importe o EmailJS
import { getTemporadaAportes, getUserById } from '../apiService'; // Importe a função da API

function Saques() {
  const [valorInvestir, setValorInvestir] = useState('');
  const [dataAporte, setDataAporte] = useState('');
  const [valorSaque, setValorSaque] = useState('');
  const [tipoFormulario, setTipoFormulario] = useState('aporte'); // Estado para controlar o tipo de formulário
  const [temporadaAportesAtiva, setTemporadaAportesAtiva] = useState(false);
  const [userInfo, setUserInfo] = useState({ name: '', email: '' }); // Estado para armazenar informações do usuário

  // Decodificar o token JWT para obter o userId
  const decodificarTokenJWT = () => {
    const token = localStorage.getItem('token'); // Pegue o token do localStorage
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload).id; // Retorne o userId
    }
    return null;
  };

  useEffect(() => {
    // Função para verificar o status da temporada de aportes
    const verificarTemporadaAportes = async () => {
      try {
        const data = await getTemporadaAportes();
        setTemporadaAportesAtiva(data.temporadaAportes === 1);
      } catch (error) {
        console.error('Erro ao obter temporada de aportes:', error);
      }
    };

    // Função para buscar informações do usuário pelo userId
    const buscarInformacoesUsuario = async () => {
      const userId = decodificarTokenJWT();
      if (userId) {
        try {
          const userData = await getUserById(userId);
          setUserInfo({ name: userData.nome, email: userData.email });
        } catch (error) {
          console.error('Erro ao obter informações do usuário:', error);
        }
      }
    };

    verificarTemporadaAportes();
    buscarInformacoesUsuario();
  }, []);

  const formatarDataBR = (data) => {
    const partesData = data.split('-'); // Divide a data no formato YYYY-MM-DD
    return `${partesData[2]}/${partesData[1]}/${partesData[0]}`; // Retorna DD/MM/YYYY
  };

  const enviarEmail = (tipo) => {
    if (tipo === 'aporte' && !temporadaAportesAtiva) {
      alert('No momento, estamos fora da temporada de aportes.');
      return;
    }

    if (tipo === 'saque' && !temporadaAportesAtiva) {
      alert('No momento, estamos fora da temporada de aportes.');
      return;
  }

  if (tipo === 'resgate' && !temporadaAportesAtiva) {
      alert('No momento, estamos fora da temporada de aportes.');
      return;
  }

    let mensagem;
  
    if (tipo === 'aporte') {
      const dataFormatada = formatarDataBR(dataAporte);
      mensagem = `Solicitação de Aporte:\n\nValor a investir: R$${valorInvestir}\nData do aporte: ${dataFormatada}.`;
    } else if (tipo === 'saque') {
      mensagem = `Solicitação de Saque:\n\nValor a sacar: R$${valorSaque}`;
    } else if (tipo === 'resgate') {
      mensagem = `Solicitação de Resgate:\n\nValor a resgatar: R$${valorSaque}`;
    }

    // Configurar os parâmetros de envio de email
    const templateParams = {
      from_name: userInfo.name, // Nome do usuário logado
      message: mensagem,
      reply_to: userInfo.email, // Email do usuário logado
    };

    // Enviar o email usando EmailJS
    emailjs.send('service_mv4kbji', 'template_yvrd8nt', templateParams, 'U7y7EdIM0B_eUGFKQ')
      .then((response) => {
        console.log('Email enviado com sucesso!', response.status, response.text);
        alert('Solicitação enviada com sucesso por email!');
      }, (err) => {
        console.error('Erro ao enviar email:', err);
        alert('Erro ao enviar solicitação. Tente novamente.');
      });
  };

  return (
    <Layout>
      <div className="containerHome logo-container">
        <div>
          <img src={logo} alt="Logo" className="logo-home" />
        </div>
        <NavLink to="/Perfil" className="profileIcon">
          <div className="profileIconWrapper">
            <FaUser size={30} />
          </div>
        </NavLink>
      </div>
      <div className="container-aporte-geral">
        {/* Formulário de Aporte */}
        <div className="aporte-container">
          <div className="aporte-group">
            <h2>SOLICITAÇÃO DE APORTE</h2>
          </div>
          <div className="formulario-duplo">
            <div className='aporte-form'>
              <label htmlFor="valor-investir">Valor a investir:</label>
              <input
                placeholder="R$"
                type="text"
                id="valor-investir"
                name="valor-investir"
                value={valorInvestir}
                onChange={(e) => setValorInvestir(e.target.value)}
              />
            </div>
            <div className='aporte-form'>
              <label htmlFor="data-aporte">Data do aporte:</label>
              <div className="input-icon">
                <FaCalendarAlt className="calendar-icon" />
                <input
                  type="date"
                  id="data-aporte"
                  name="data-aporte"
                  value={dataAporte}
                  onChange={(e) => setDataAporte(e.target.value)}
                />
              </div>
            </div>
          </div>
          <button
            className="whatsapp-button"
            onClick={() => enviarEmail('aporte')}
          >
            ENVIAR
          </button>
        </div>
        <div className="aporte-group-resgate">
          <h2>SOLICITAÇÃO DE SAQUE OU RESGATE</h2>

          {/* Seleção de SAQUE ou RESGATE */}
          <div className="selecao-formulario">
            <span 
              className={`selecao-item ${tipoFormulario === 'saque' ? 'ativo' : ''}`}
              onClick={() => setTipoFormulario('saque')}
            >
              SAQUE
            </span>
            <span 
              className={`selecao-item ${tipoFormulario === 'resgate' ? 'ativo' : ''}`}
              onClick={() => setTipoFormulario('resgate')}
            >
              RESGATE
            </span>
          </div>
        </div>

        {/* Formulário de Saque */}
        {tipoFormulario === 'saque' && (
          <div className="aporte-container">
            <div className="formulario-duplo">
              <div className='aporte-form'>
                <label htmlFor="valor-saque">Valor requerido:</label>
                <input
                  placeholder="R$"
                  type="text"
                  id="valor-saque"
                  name="valor-saque"
                  value={valorSaque}
                  onChange={(e) => setValorSaque(e.target.value)}
                />
              </div>
            </div>
            <div className='button-container-saques'>
              <button
                className="whatsapp-button-saque"
                onClick={() => enviarEmail('saque')}
              >
                ENVIAR
              </button>
            </div>
          </div>
        )}

        {/* Formulário de Resgate */}
        {tipoFormulario === 'resgate' && (
          <div className="aporte-container resgate-form">
            <div className="formulario-duplo">
              <div className='aporte-form'>
                <label htmlFor="valor-resgate">Valor requerido:</label>
                <input
                  placeholder="R$"
                  type="text"
                  id="valor-resgate"
                  name="valor-resgate"
                  value={valorSaque}
                  onChange={(e) => setValorSaque(e.target.value)}
                />
              </div>
            </div>
            <div className='button-container-saques'>
              <button
                className="whatsapp-button-resgate"
                onClick={() => enviarEmail('resgate')}
              >
                ENVIAR
              </button>
            </div>
          </div>
        )}

        {/* Botão de Extrato de Investimentos */}
        <button className="extrato-button" disabled>
          <FaFileAlt className="extrato-icon" /> EXTRATO DE INVESTIMENTOS
        </button>
      </div>
    </Layout>
  );
}

export default Saques;
