import React, { useEffect, useState } from 'react';
import { FaEye, FaTrash, FaCheck, FaFile } from 'react-icons/fa';
import './Admin.css';
import './Rendimentos.css';
import { Link } from 'react-router-dom';
import 'smart-webcomponents-react/source/styles/smart.default.css';
import { getAllUsers, getUserById, updateUser, deleteUser, getUserInvestments, updateUserInvestments, uploadPdfProjetos, uploadPdfContratos, getUserPdfListProjetos, getUserPdfListContratos, deletePdfProjetos, deletePdfContratos, addGraficoInicial, getGraficoInicial, deleteGraficoInicial } from '../apiService.js';

function Users() {
  const [userNames, setUserNames] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [rendimentos, setRendimentos] = useState([]);
  const [userInvestments, setUserInvestments] = useState({});
  const [capitalTotalGeneral, setCapitalTotalGeneral] = useState('');
  const [anexosExistentes, setAnexosExistentes] = useState([]);
  const [contratosExistentes, setContratosExistentes] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [permissaoId, setPermissaoId] = useState(null);

  // Estado para o novo input
  const [mes, setMes] = useState('');
  const [percentual, setPercentual] = useState('');
  const [ano, setAno] = useState('');

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const fetchUsers = async () => {
    try {
      const data = await getAllUsers();
      setUserNames(data);
    } catch (error) {
      console.error('Erro ao buscar nomes de usuários:', error);
    }
  };

  const fetchUserPdfs = async (userId) => {
    try {
      const projetos = await getUserPdfListProjetos(userId);
      const contratos = await getUserPdfListContratos(userId);

      setAnexosExistentes(projetos || []);
      setContratosExistentes(contratos || []);
    } catch (error) {
      console.error('Erro ao buscar PDFs do usuário:', error);
      setAnexosExistentes([]);
      setContratosExistentes([]);
    }
  };

  useEffect(() => {
    fetchUsers();
    const token = localStorage.getItem('token');
    const decodedToken = parseJwt(token);
    setPermissaoId(decodedToken.permissao_id);
  }, []);

  useEffect(() => {
    if (shouldUpdate) {
      fetchUsers();
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  const handleUserChange = async (event) => {
    const userId = event.target.value;
    if (userId) {
      try {
        const userData = await getUserById(userId);
        setSelectedUser(userData);

        const investmentsData = await getUserInvestments(userId);
        setUserInvestments(investmentsData);
        setCapitalTotalGeneral(investmentsData.capitalTotal || '');

        await fetchUserPdfs(userId);
        await fetchRendimentos(userId);

      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
      }
    } else {
      setSelectedUser(null);
      setCapitalTotalGeneral('');
      setUserInvestments({});
      setAnexosExistentes([]);
      setContratosExistentes([]);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (selectedUser) {
      const userData = {
        ...selectedUser,
        ativo: selectedUser.ativo ? 1 : 0
      };

      try {
        await updateUser(selectedUser.usuario_id, userData);
        const updatedInvestments = {
          ...userInvestments,
          capitalTotal: capitalTotalGeneral
        };
        await updateUserInvestments(selectedUser.usuario_id, updatedInvestments);

        alert('Dados do usuário atualizados com sucesso');

        setSelectedUser(null);
        setCapitalTotalGeneral('');
        setUserInvestments({});
        setAnexosExistentes([]);
        setContratosExistentes([]);
        setShouldUpdate(true);

      } catch (error) {
        console.error('Erro ao atualizar dados do usuário:', error);
      }
    }
  };

  const handleUserDelete = async () => {
    if (selectedUser) {
      try {
        await deleteUser(selectedUser.usuario_id);
        alert('Usuário deletado com sucesso');
      } catch (error) {
        console.error('Erro ao deletar usuário:', error);
      }
    }
  };

  const handleFileUpload = async (event, type) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const pdfName = file.name;
      const userId = selectedUser?.usuario_id;

      try {
        if (type === 'projetos') {
          await uploadPdfProjetos(file, pdfName, userId);
        } else if (type === 'contratos') {
          await uploadPdfContratos(file, pdfName, userId);
        }
        alert('Arquivo enviado com sucesso');
        await fetchUserPdfs(userId);
      } catch (error) {
        console.error('Erro ao enviar arquivo:', error);
        alert('Erro ao enviar arquivo');
      }
    }
  };

  const handleDeletePdf = async (pdf_id, type) => {
    try {
      const userId = selectedUser?.usuario_id;

      if (type === 'projetos') {
        await deletePdfProjetos(pdf_id, userId);
      } else if (type === 'contratos') {
        await deletePdfContratos(pdf_id, userId);
      }

      alert('Arquivo excluído com sucesso');
      await fetchUserPdfs(userId);
    } catch (error) {
      console.error('Erro ao excluir arquivo:', error);
      alert('Erro ao excluir arquivo');
    }
  };

  const handleDownload = (pdfData) => {
    try {
      const blob = new Blob([new Uint8Array(pdfData.pdfData.data)], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = pdfData.pdfName || 'document.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao baixar o PDF:', error);
    }
  };

  const handleAddRendimento = async (event) => {
    event.preventDefault();

    // Validar os valores
    if (!mes || isNaN(parseFloat(percentual)) || isNaN(parseInt(ano))) {
      alert('Por favor, preencha todos os campos corretamente.');
      return;
    }

    const rendimentoData = {
      mes,
      percentual: parseFloat(percentual),
      ano: parseInt(ano, 10)
    };

    try {
      // Adiciona o rendimento
      await addGraficoInicial(rendimentoData, selectedUser?.usuario_id);
      alert('Rendimento mensal adicionado com sucesso.');

      // Atualiza a lista de rendimentos
      setRendimentos([
        ...rendimentos,
        rendimentoData // Adiciona o novo rendimento à lista existente
      ]);

      // Limpa os campos do formulário, se desejado
      setMes('');
      setPercentual('');
      setAno('');
    } catch (error) {
      console.error('Erro ao adicionar rendimento mensal:', error);
      alert('Erro ao adicionar rendimento mensal.');
    }
  };

  const fetchRendimentos = async (userId) => {
    try {
      const rendimentosData = await getGraficoInicial(userId);
      setRendimentos(rendimentosData); // Armazena os rendimentos no estado
    } catch (error) {
      console.error('Erro ao buscar rendimentos mensais:', error);
    }
  };

  const handleDeleteRendimento = async (graficoInicial_id) => {
    try {
      await deleteGraficoInicial(graficoInicial_id);
      alert('Rendimento removido com sucesso.');
      // Atualiza a lista de rendimentos após a exclusão
      setRendimentos(rendimentos.filter(rendimento => rendimento.graficoInicial_id !== graficoInicial_id));
    } catch (error) {
      console.error('Erro ao remover rendimento:', error);
      alert('Erro ao remover rendimento.');
    }
  };

  // eslint-disable-next-line no-lone-blocks
  {/*// Dados fictícios para a tabela de investimentos
  const investmentColumns = [
    { label: 'Capital Inicial', dataField: 'capitalInicial', dataType: 'number' },
    { label: 'Capital Investido', dataField: 'capitalInvestido', dataType: 'number' },
    { label: 'Rendimentos', dataField: 'rendimentos', dataType: 'number' },
    { label: 'Saques', dataField: 'saques', dataType: 'number' },
    { label: 'Resgate', dataField: 'resgate', dataType: 'number' },
    { label: 'Aporte', dataField: 'aporte', dataType: 'number' },
  ];

  // Dados fictícios para a tabela de investimentos
  const investmentData = [
    { id: 1, capitalInicial: 1000, capitalInvestido: 5000, rendimentos: 200, saques: 100, resgate: 50, aporte: 300 },
    { id: 2, capitalInicial: 2000, capitalInvestido: 10000, rendimentos: 400, saques: 200, resgate: 100, aporte: 600 },
    { id: 3, capitalInicial: 3000, capitalInvestido: 15000, rendimentos: 600, saques: 300, resgate: 150, aporte: 900 },
    { id: 4, capitalInicial: 4000, capitalInvestido: 20000, rendimentos: 800, saques: 400, resgate: 200, aporte: 1200 },
    { id: 5, capitalInicial: 5000, capitalInvestido: 25000, rendimentos: 1000, saques: 500, resgate: 250, aporte: 1500 },
  ];*/}

  return (
    <div className="user-info">
      <h2>INFORMAÇÕES GERAIS <FaEye className="icon" /></h2>
      <Link to="/create-user" className="create-user-button">Criar Novo Usuário</Link>
      <form className="user-form" onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nome:</label>
          <select id="name" name="name" required onChange={handleUserChange}>
            <option value="">Selecione um nome</option>
            {userNames.map((user, index) => (
              <option key={index} value={user.usuario_id}>{user.nome}</option>
            ))}
          </select>
        </div>

        {selectedUser && (
          <>
            <div className="form-group">
              <label htmlFor="cpf">CPF:</label>
              <input placeholder="Ex: 000.000.000-00" type="text" id="cpf" name="cpf" required value={selectedUser?.cpf || ''} onChange={(e) => setSelectedUser({ ...selectedUser, cpf: e.target.value })} />
            </div>
            <div className="form-group">
              <label htmlFor="capital">Capital Inicial:</label>
              <input placeholder="R$ 100.000,00" type="text" id="capital" name="capital" required value={selectedUser?.capitalInicial || ''} onChange={(e) => setSelectedUser({ ...selectedUser, capitalInicial: e.target.value })} />
            </div>
            <div className="form-group">
              <label htmlFor="capitalTotal">Capital Total:</label>
              <input
                placeholder="R$ 100.000,00"
                type="text"
                id="capitalTotal"
                name="capitalTotal"
                required
                value={capitalTotalGeneral}
                onChange={(e) => setCapitalTotalGeneral(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="modalidade">Modalidade:</label>
              <select id="modalidade" name="modalidade" required value={selectedUser?.modalidade || ''} onChange={(e) => setSelectedUser({ ...selectedUser, modalidade: e.target.value })}>
                <option value="">Selecione uma modalidade</option>
                <option value="Anual">Anual</option>
                <option value="Trimestral">Trimestral</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input placeholder="Ex: teste@gmail.com" type="email" id="email" name="email" required value={selectedUser?.email || ''} onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })} />
            </div>
            <div className="form-group">
              <label htmlFor="telefone">Telefone:</label>
              <input placeholder="Ex: (00)90000-0000" type="text" id="telefone" name="telefone" required value={selectedUser?.telefone || ''} onChange={(e) => setSelectedUser({ ...selectedUser, telefone: e.target.value })} />
            </div>
            <div className="form-group">
              <label htmlFor="senha">Senha:</label>
              <input placeholder="Ex: ********" type="password" id="senha" name="senha" required value={selectedUser?.senha || ''} onChange={(e) => setSelectedUser({ ...selectedUser, senha: e.target.value })} />
            </div>
            <div className="form-group">
              <label htmlFor="estadoCivil">Estado Civil:</label>
              <select id="estadoCivil" name="estadoCivil" required value={selectedUser?.estadoCivil || ''} onChange={(e) => setSelectedUser({ ...selectedUser, estadoCivil: e.target.value })}>
                <option value="">Selecione um estado civil</option>
                <option value="Solteiro">Solteiro(a)</option>
                <option value="Casado">Casado(a)</option>
                <option value="Divorciado">Divorciado(a)</option>
                <option value="Viuvo">Viúvo(a)</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="ocupacao">Ocupação:</label>
              <input placeholder="Ex: Investidor" type="text" id="ocupacao" name="ocupacao" required value={selectedUser?.ocupacao || ''} onChange={(e) => setSelectedUser({ ...selectedUser, ocupacao: e.target.value })} />
            </div>
            <div className="form-group">
              <label htmlFor="endereco">Endereço:</label>
              <input placeholder="Digite aqui o endereço completo (Rua, Bairro, Cidade, UF e complemento)" type="text" id="endereco" name="endereco" required value={selectedUser?.endereco || ''} onChange={(e) => setSelectedUser({ ...selectedUser, endereco: e.target.value })} />
            </div>
            <div className="form-group" style={{ flexBasis: 'calc(50% - 10px)' }}>
              <label htmlFor="comentarios">Comentários Gerais:</label>
              <textarea placeholder="Digite qualquer comentário geral que deseje." id="comentarios" name="comentarios" value={selectedUser?.comentarioGeral || ''} onChange={(e) => setSelectedUser({ ...selectedUser, comentarioGeral: e.target.value })}></textarea>
            </div>
          </>
        )}

        {selectedUser && permissaoId === 1 && (
          <div className="form-group">
            <label htmlFor="permissao">Permissão:</label>
            <select id="permissao" name="permissao" required value={selectedUser?.permissao_id || ''} onChange={(e) => setSelectedUser({ ...selectedUser, permissao_id: e.target.value })}>
              <option value="">Selecione uma permissão</option>
              <option value="2">Usuário</option>
              <option value="3">Administrador</option>
              <option value="1">Administrador Master</option>
            </select>
          </div>
        )}

        {selectedUser && (
          <div className="form-group slide-container" style={{ marginTop: '20px' }}>
            <label htmlFor="ativo">Usuário Ativo?</label>
            <div className="slide-container">
              <input type="checkbox" id="ativo" name="ativo" checked={selectedUser?.ativo === 1} onChange={(e) => setSelectedUser({ ...selectedUser, ativo: e.target.checked ? 1 : 0 })} />
              <label className="slide" htmlFor="ativo"></label>
            </div>
            {permissaoId === 1 && (
              <button className="delete-button" type="button" onClick={handleUserDelete}>
                <FaTrash className="delete-icon" /> Deletar Usuário
              </button>
            )}
            <button className="save-button" type="submit" onClick={handleFormSubmit}><FaCheck className="icon" /> Salvar</button>

          </div>
        )}
      </form>

      {selectedUser && (
        <><div className="anexo-info">
          <h2>ANEXOS <FaEye className="icon" /></h2>
          <form className="anexo-form">
            <div className="form-group">
              <label htmlFor="projetos">Projetos:</label>
              <input type="file" id="projetos" name="projetos" onChange={(e) => handleFileUpload(e, 'projetos')} />
              <div className="anexos-existentes">
                <ul>
                  {anexosExistentes.length > 0 ? (
                    anexosExistentes.map(anexo => (
                      <li key={anexo.projetosPdf_id}>
                        <span className="pdf-label projeto-anexado-label">Projeto Anexado:</span>
                        <button onClick={() => handleDownload(anexo)}>
                          <FaFile className="icon-download" />
                          <span className="pdf-nome"> {anexo.pdfName}</span>
                        </button>
                        <FaTrash
                          className="icon-delete"
                          onClick={() => handleDeletePdf(anexo.projetosPdf_id, 'projetos')} // Use o campo correto para deletar
                        />
                      </li>
                    ))
                  ) : (
                    <li>Nenhum projeto anexado.</li>
                  )}
                </ul>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="contratos">Contratos:</label>
              <input type="file" id="contratos" name="contratos" onChange={(e) => handleFileUpload(e, 'contratos')} />
              <div className="anexos-existentes">
                <ul>
                  {contratosExistentes.length > 0 ? (
                    contratosExistentes.map(anexo => (
                      <li key={anexo.contratosPdf_id}>
                        <span className="pdf-label projeto-anexado-label">Contrato Anexado:</span>
                        <button onClick={() => handleDownload(anexo)}>
                          <FaFile className="icon-download" />
                          <span className="pdf-nome"> {anexo.pdfName}</span>
                        </button>
                        <FaTrash className="icon-lixo" onClick={() => handleDeletePdf(anexo.contratosPdf_id, 'contratos')} />
                      </li>
                    ))
                  ) : (
                    <li>Nenhum contrato anexado.</li>
                  )}
                </ul>
              </div>
            </div>
          </form>
        </div>


          <div className="resumo-info">
            <h2>RESUMO <FaEye className="icon" /></h2>
            <form className="resumo-form">
              <div className="form-group">
                <label htmlFor="capitalTotalResumo">Capital Total:</label>
                <input
                  placeholder="Ex: R$10.000,00"
                  type="text"
                  id="capitalTotalResumo"
                  name="capitalTotalResumo"
                  required
                  value={userInvestments?.capitalTotal || ''}
                  onChange={(e) => setUserInvestments({ ...userInvestments, capitalTotal: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="CapitalInvestido">Capital Investido:</label>
                <input
                  placeholder="Ex: R$50.000,00"
                  type="text"
                  id="CapitalInvestido"
                  name="CapitalInvestido"
                  required
                  value={userInvestments?.capitalInvestido || ''}
                  onChange={(e) => setUserInvestments({ ...userInvestments, capitalInvestido: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Rendimentos">Rendimentos:</label>
                <input
                  placeholder="Ex: R$500,00"
                  type="text"
                  id="Rendimentos"
                  name="Rendimentos"
                  required
                  value={userInvestments?.rendimento || ''}
                  onChange={(e) => setUserInvestments({ ...userInvestments, rendimento: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Saques">Saques:</label>
                <input
                  placeholder="Ex: R$1.000,00"
                  type="text"
                  id="Saques"
                  name="Saques"
                  required
                  value={userInvestments?.saque || ''}
                  onChange={(e) => setUserInvestments({ ...userInvestments, saque: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Resgates">Resgates:</label>
                <input
                  placeholder="Ex: R$500,00"
                  type="text"
                  id="Resgates"
                  name="Resgates"
                  required
                  value={userInvestments?.resgate || ''}
                  onChange={(e) => setUserInvestments({ ...userInvestments, resgate: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Aporte">Aportes:</label>
                <input
                  placeholder="Ex: R$500,00"
                  type="text"
                  id="Aporte"
                  name="Aporte"
                  required
                  value={userInvestments?.aporte || ''}
                  onChange={(e) => setUserInvestments({ ...userInvestments, aporte: e.target.value })}
                />
              </div>
            </form>
          </div>

          {/* Novo campo de input para adicionar rendimento mensal */}
          <div className="add-rendimento">
            <h2>GRÁFICO <FaEye className="icon" /></h2>
            <form className="rendimento-form" onSubmit={handleAddRendimento}>
              <div className="form-group">
                <label htmlFor="mes">Mês:</label>
                <select
                  id="mes"
                  value={mes}
                  onChange={(e) => setMes(e.target.value)}
                >
                  <option value="">Selecione o mês</option>
                  {['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'].map((month) => (
                    <option key={month} value={month}>{month}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="porcentagem">Porcentagem:</label>
                <input
                  type="number"
                  id="porcentagem"
                  step="0.1"
                  value={percentual}
                  onChange={(e) => setPercentual(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="ano">Ano:</label>
                <input
                  type="text"
                  id="ano"
                  value={ano}
                  onChange={(e) => setAno(e.target.value)}
                />
              </div>

              <button type="submit" className="save-button">Adicionar Rendimento</button>
            </form>

            <div className="relatorio-list">
              <h2>Rendimentos Cadastrados</h2>
              <ul>
                {rendimentos.length > 0 ? (
                  rendimentos.map((rendimento) => (
                    <li key={rendimento.graficoInicial_id} className="rendimento-item">
                      <div className="rendimento-info">
                        <span className="rendimento-mes">Mês: {rendimento.mes}</span>
                        <span className="rendimento-percentual">Porcentagem: {rendimento.percentual}%</span>
                        <span className="rendimento-ano">Ano: {rendimento.ano}</span>
                        <FaTrash
                          className="icon-delete"
                          onClick={() => handleDeleteRendimento(rendimento.graficoInicial_id)}
                          title="Excluir rendimento"
                        />
                      </div>
                    </li>
                  ))
                ) : (
                  <li>Nenhum rendimento cadastrado.</li>
                )}
              </ul>
            </div>


          </div>


          {/*
              <div className="calc-info">
                <h2>CÁLCULO PARA LANÇAMENTOS <FaEye className="icon" /></h2>
                <form className="calc-form">
                  <div className="form-group">
                    <label htmlFor="percentual">Percentual:</label>
                    <input placeholder="Ex: 3,00%" type="text" id="percentual" name="percentual" required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="retorno">Retorno:</label>
                    <input placeholder="Ex: R$3.000,00" type="text" id="retorno" name="retorno" required />
                  </div>
                </form>
              </div>

              <div className="geral-info">
                <h2>QUADRO GERAL</h2>
                <div className="investment-table-container" style={{ height: '400px' }}>
                  <Table
                    className="investment-table"
                    dataSource={investmentData}
                    columns={investmentColumns}
                    editing={true}
                    editMode="cell"
                  />
                </div>
              </div>*/}
        </>
      )}
    </div>
  );
}
export default Users;