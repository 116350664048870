import React, { useEffect, useState } from 'react';
import { getUserInvestments, getUserById } from '../apiService'; // Importe a função do serviço

function VisaoGeral() {
    const [investimentos, setInvestimentos] = useState(null);
    const [usuario, setUsuario] = useState(null);

    // Função para buscar investimentos de um usuário
    const fetchUserInvestments = async (userId) => {
        try {
            const data = await getUserInvestments(userId);
            setInvestimentos(data);
        } catch (error) {
            console.error('Erro ao buscar investimentos:', error);
        }
    };

    // Função para buscar informações do usuário
    const fetchUser = async (userId) => {
        try {
            const data = await getUserById(userId);
            setUsuario(data);
        } catch (error) {
            console.error('Erro ao buscar dados do usuário:', error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        if (decodedToken.id) {
            fetchUserInvestments(decodedToken.id); // Buscar investimentos do usuário
            fetchUser(decodedToken.id); // Buscar dados do usuário
        }
    }, []);

    if (!investimentos || !usuario) return <p>Carregando...</p>;

    return (
        <div>
            <p>Capital Investido: R${investimentos.capitalInvestido}</p>
            <p>Modalidade: {usuario.modalidade}</p>
            <p>Rendimentos: R${investimentos.rendimento}</p>
            <p>Saques: R${investimentos.saque}</p>
            <p>Resgates: R${investimentos.resgate}</p>
            <p>Aportes: R${investimentos.aporte}</p>
        </div>
    );
}

export default VisaoGeral;
