import React from 'react';
import './Charts.css';
import Anual from './Anual'; // Importa o componente Anual
import Trimestral from './Trimestral';

function Charts() {
    return (
        <div className="chart-content">
          <h2>GRÁFICO ANUAL</h2>
            <div className="add-rendimento">
                {/* Renderiza o componente Anual */}
                <Anual />
            </div>
            <div className="add-rendimento-trimestral">
                <h2>GRÁFICO TRIMESTRAL</h2>
                {/* Renderiza o componente Anual */}
                <Trimestral />
            </div>
        </div>
    );
}

export default Charts;
