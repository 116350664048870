import React from 'react';
import './Iniciar.css';
import { useNavigate } from "react-router-dom";

function Iniciar() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/Login");
    };

    return (
        <>
            <div className="container">
                <img src="assets/logo.png" alt="Logo" className="logo" />
            </div>
            <button id="botao" className="botao" onClick={handleButtonClick}>Entrar</button>
        </>
    );
}

export default Iniciar;
