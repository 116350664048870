import React, { useState, useEffect } from 'react'; // Importe useState e useEffect
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './pages/Iniciar/Iniciar.css';
import Iniciar from './pages/Iniciar/Iniciar.js';
import Login from './pages/Login/Login.js';
import HomePage from './pages/HomePage/HomePage.js';
import Carteiras from './pages/Carteiras/Carteiras.js';
import Saques from './pages/Saques/Saques.js';
import Avisos from './pages/Avisos/Avisos.js';
import Projetos from './pages/Projetos/Projetos.js';
import Admin from './pages/Admin/Admin.js';
import Perfil from './pages/Perfil/Perfil.js';
import CreateUserForm from './pages/Admin/CreateUserForm.js';
import Anual from './pages/Carteiras/Carteiras/Anual.js';
import Trimestral from './pages/Carteiras/Carteiras/Trimestral.js';
import Dados from './pages/Perfil/Perfil/Dados.js'

function App() {
  // Defina o componente PrivateRoute dentro da função App
  const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    const [permissaoId, setPermissaoId] = useState(null);

    useEffect(() => {
      const token = localStorage.getItem('token');
      const decodedToken = parseJwt(token);
      setPermissaoId(decodedToken.permissao_id);
    }, []);

    // Função para decodificar o token JWT
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split('.')[1]));
      } catch (e) {
        return null;
      }
    };


    // Verifica se o permissao_id é igual a 1 para acessar a página /Admin
    const isAdmin = permissaoId === 1 || permissaoId === 3;

    if (isAdmin && window.location.pathname === '/Admin') {
      return token ? children : <Navigate to="/Login" />;
    } else if (!isAdmin && window.location.pathname === '/Admin') {
      return <Navigate to="/Home" />;
    }

    return token ? children : <Navigate to="/Login" />;
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Iniciar />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Home" element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        } />
        <Route path="/Carteiras" element={
          <PrivateRoute>
            <Carteiras />
          </PrivateRoute>
        } />
        <Route path="/Saques" element={
          <PrivateRoute>
            <Saques />
          </PrivateRoute>
        } />
        <Route path="/Avisos" element={
          <PrivateRoute>
            <Avisos />
          </PrivateRoute>
        } />
        <Route path="/Projetos" element={
          <PrivateRoute>
            <Projetos />
          </PrivateRoute>
        } />
        <Route path="/Admin" element={
          <PrivateRoute>
            <Admin />
          </PrivateRoute>
        } />
        <Route path="/Perfil" element={
          <PrivateRoute>
            <Perfil />
          </PrivateRoute>
        } />
        <Route path="/create-user" element={
          <PrivateRoute>
            <CreateUserForm />
          </PrivateRoute>
        } />
                <Route path="/Carteiras/Trimestral" element={
          <PrivateRoute>
            <Trimestral />
          </PrivateRoute>
        } />
                <Route path="/Carteiras/Anual" element={
          <PrivateRoute>
            <Anual />
          </PrivateRoute>
        } />
                <Route path="/Perfil/Dados" element={
          <PrivateRoute>
            <Dados />
          </PrivateRoute>
        } />
      </Routes>
    </Router>
  );
}

export default App;
