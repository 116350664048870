// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.relatorio-list {
    font-family: sans-serif;
}

.relatorio-list ul {
    list-style-type: none;
    padding: 0;
}

.relatorio-list li {
    margin-bottom: 10px;
}

.rendimento-info {
    display: flex;
    flex-direction: column;
}

.rendimento-mes,
.rendimento-percentual,
.rendimento-ano {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 5px;
}

.rendimento-percentual {
    color: #52d88a; /* Destaque para a porcentagem */
}

.rendimento-item {
    background: rgba(60, 31, 91, 0.8); /* Exemplo de fundo para item */
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #9A52D8;
}

.rendimento-item button {
    background: none;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.rendimento-item button:focus {
    outline: none;
}

.icon-download {
    color: white;
    margin-left: 10px;
    font-size: 14px;
}

.icon-delete {
    color: red;
    margin-left: 10px;
    cursor: pointer;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Rendimentos.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;;;IAGI,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,cAAc,EAAE,gCAAgC;AACpD;;AAEA;IACI,iCAAiC,EAAE,+BAA+B;IAClE,aAAa;IACb,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,eAAe;IACf,eAAe;AACnB","sourcesContent":[".relatorio-list {\n    font-family: sans-serif;\n}\n\n.relatorio-list ul {\n    list-style-type: none;\n    padding: 0;\n}\n\n.relatorio-list li {\n    margin-bottom: 10px;\n}\n\n.rendimento-info {\n    display: flex;\n    flex-direction: column;\n}\n\n.rendimento-mes,\n.rendimento-percentual,\n.rendimento-ano {\n    color: #ffffff;\n    font-size: 16px;\n    margin-bottom: 5px;\n}\n\n.rendimento-percentual {\n    color: #52d88a; /* Destaque para a porcentagem */\n}\n\n.rendimento-item {\n    background: rgba(60, 31, 91, 0.8); /* Exemplo de fundo para item */\n    padding: 10px;\n    border-radius: 5px;\n    border: 1px solid #9A52D8;\n}\n\n.rendimento-item button {\n    background: none;\n    border: none;\n    padding: 10px 15px;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 14px;\n}\n\n.rendimento-item button:focus {\n    outline: none;\n}\n\n.icon-download {\n    color: white;\n    margin-left: 10px;\n    font-size: 14px;\n}\n\n.icon-delete {\n    color: red;\n    margin-left: 10px;\n    cursor: pointer;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
