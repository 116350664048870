/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './General.css';
import { getTemporadaAportes, updateTemporadaAportes, uploadPdfRelatorios, uploadPdfMateriais, getAllUsers } from '../../apiService'; // Corrigido o caminho
import RelatorioList from './Relatorios/RelatorioList';
import MateriaisList from './Materiais/MateriaisList';
import AvisosSection from './Avisos/AvisosSection'; // Importe o novo componente
import { FaPaperclip } from 'react-icons/fa';
import AtivosEmOperacao from './Ativos/AtivosEmOperacao';

const General = () => {
    const [temporadaAportes, setTemporadaAportes] = useState(false);
    const [pdfFile, setFile] = useState(null);
    const [pdfName, setFileName] = useState('');
    const [userId, setUserId] = useState(null);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchTemporadaAportes = async () => {
            try {
                const response = await getTemporadaAportes();
                setTemporadaAportes(response.temporadaAportes === 1);
            } catch (error) {
                console.error('Erro ao buscar temporada de aportes:', error);
            }
        };

        fetchTemporadaAportes();
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await getAllUsers();
                setUsers(data);
            } catch (error) {
                console.error('Erro ao buscar usuários:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleToggle = async () => {
        const newValue = !temporadaAportes;
        setTemporadaAportes(newValue);

        try {
            await updateTemporadaAportes(newValue ? 1 : 0);
        } catch (error) {
            console.error('Erro ao atualizar temporada de aportes:', error);
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
            setFileName(file.name);

            if (userId) {
                // Enviar o PDF automaticamente após a seleção
                try {
                    if (event.target.id === 'file-upload-relatorio') {
                        await uploadPdfRelatorios(file, file.name, userId);
                    } else if (event.target.id === 'file-upload-materiais') {
                        await uploadPdfMateriais(file, file.name, userId);
                    }
                    alert('PDF enviado com sucesso.');
                } catch (error) {
                    console.error('Erro ao enviar PDF:', error);
                }

                // Limpar o arquivo selecionado após o envio
                setFile(null);
                setFileName('');
            } else {
                alert('Por favor, selecione um usuário para o upload.');
            }
        }
    };

    return (
        <div className="general-content">
            <div className="general-container">
                <div className="general-label-container">
                    <label className="general-label">
                        Temporada de aportes?
                    </label>
                    <div className="general-slide">
                        <input
                            type="checkbox"
                            id="temporada-aportes"
                            className="general-checkbox"
                            checked={temporadaAportes}
                            onChange={handleToggle}
                        />
                        <label className="label-slide" htmlFor="temporada-aportes"></label>
                    </div>
                </div>

                <div className="user-select-container">
                    <select
                        id="user-select"
                        onChange={(e) => setUserId(e.target.value)}
                        className="user-select"
                    >
                        <option value="">Selecione um usuário</option>
                        {users.map(user => (
                            <option key={user.usuario_id} value={user.usuario_id}>
                                {user.nome}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="upload-container">
                    <div className="upload-header">
                        <label className="general-label">
                            Relatórios:
                        </label>
                        <div className="upload-form">
                            <label htmlFor="file-upload-relatorio" className="upload-file-button">
                                <FaPaperclip className="icon" /> ANEXAR RELATÓRIO
                            </label>
                            <input
                                type="file"
                                id="file-upload-relatorio"
                                accept=".pdf"
                                onChange={handleFileChange}
                                className="upload-file-input"
                            />
                        </div>
                    </div>
                    <div className="pdf-list-section">
                        <RelatorioList userId={userId} />
                    </div>
                    <div className="upload-header">
                        <label className="general-label">
                            Materiais:
                        </label>
                        <div className="upload-form">
                            <label htmlFor="file-upload-materiais" className="upload-file-button">
                                <FaPaperclip className="icon" /> ANEXAR MATERIAL
                            </label>
                            <input
                                type="file"
                                id="file-upload-materiais"
                                accept=".pdf"
                                onChange={handleFileChange}
                                className="upload-file-input"
                            />
                        </div>
                    </div>
                    <div className="pdf-list-section">
                        <MateriaisList userId={userId} />
                    </div>
                </div>

                {/* Use o componente AvisosSection aqui */}
                <AvisosSection />
                {/* Adicione o novo componente AtivosEmOperacao */}
                <AtivosEmOperacao />
            </div>
        </div>
    );
};

export default General;
