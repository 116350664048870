import React, { useState, useEffect } from 'react';
import { getAtivosEmOperacao, addAtivo, deleteAtivo } from '../../../apiService'; // Ajuste o caminho conforme necessário
import './AtivosEmOperacao.css'; // Crie o arquivo CSS correspondente
import { FaTrash } from 'react-icons/fa';

const AtivosEmOperacao = () => {
    const [ativos, setAtivos] = useState([]);
    const [tipoCarteira, setTipoCarteira] = useState('');
    const [ativo, setAtivo] = useState('');
    const [porcentagem, setPorcentagem] = useState('');

    useEffect(() => {
        const fetchAtivos = async () => {
            try {
                const response = await getAtivosEmOperacao();
                setAtivos(response);
            } catch (error) {
                console.error('Erro ao buscar ativos em operação:', error);
            }
        };

        fetchAtivos();
    }, []);

    const handleAddAtivo = async (event) => {
        event.preventDefault();

        if (!ativo.trim() || !porcentagem.trim() || !tipoCarteira.trim()) {
            alert('Por favor, preencha todos os campos.');
            return;
        }

        try {
            const ativoData = { tipoCarteira, ativo, porcentagem };
            await addAtivo(ativoData);
            alert('Ativo adicionado com sucesso.');
            setAtivo('');
            setPorcentagem('');
            setTipoCarteira('');
            // Atualiza a lista de ativos
            const response = await getAtivosEmOperacao();
            setAtivos(response);
        } catch (error) {
            console.error('Erro ao adicionar ativo:', error);
            alert('Erro ao adicionar ativo.');
        }
    };

    const handleDeleteAtivo = async (ativo_id) => {
        try {
            await deleteAtivo(ativo_id);
            alert('Ativo deletado com sucesso.');
            // Atualiza a lista de ativos após a exclusão
            setAtivos(ativos.filter(a => a.ativo_id !== ativo_id));
        } catch (error) {
            console.error('Erro ao deletar ativo:', error);
            alert('Erro ao deletar ativo.');
        }
    };

    return (
        <div className="ativos-em-operacao">
            <h2>Ativos em operação</h2>
            <div className="ativos-form-container">
                <h3>Adicionar Novo Ativo</h3>
                <form onSubmit={handleAddAtivo}>
                    <label htmlFor="tipoCarteira">Tipo de Carteira:</label>
                    <select
                        id="tipoCarteira"
                        value={tipoCarteira}
                        onChange={(e) => setTipoCarteira(e.target.value)}
                    >
                        <option value="">Selecione</option>
                        <option value="Trimestral">Trimestral</option>
                        <option value="Anual">Anual</option>
                    </select>

                    <label htmlFor="ativo">Ativo:</label>
                    <input
                        type="text"
                        id="ativo"
                        value={ativo}
                        onChange={(e) => setAtivo(e.target.value)}
                        placeholder="Digite o nome do ativo"
                    />

                    <label htmlFor="porcentagem">Porcentagem:</label>
                    <input
    type="text"
    id="porcentagem"
    value={porcentagem}
    onChange={(e) => setPorcentagem(e.target.value)}
    placeholder="Digite a porcentagem"
    min="0"
    max="100"
    step="0.1"  // Permite valores decimais com uma casa decimal
/>

                    <button type="submit">Adicionar Ativo</button>
                </form>
            </div>
            <ul className="ativos-list">
                {ativos.length > 0 ? (
                    ativos.map((item, index) => (
                        <li key={index}>
                            {item.tipoCarteira} - {item.ativo} - {item.porcentagem}%
                            <FaTrash 
                                className="icon-delete"
                                onClick={() => handleDeleteAtivo(item.ativo_id)} // Use o campo correto para deletar
                            />
                        </li>
                    ))
                ) : (
                    <li>Nenhum ativo em operação</li>
                )}
            </ul>
        </div>
    );
};

export default AtivosEmOperacao;
