import React, { useState, useEffect } from "react";
import "./Projetos.css";
import Layout from "../Components/Layout.js";
import { NavLink } from "react-router-dom";
import { FaUser, FaFile, FaCalendarAlt } from "react-icons/fa";
import logo from "../Components/assets/logo.png";
import { getUserPdfListProjetos, getUserById } from "../apiService";
import emailjs from 'emailjs-com';

const Projetos = () => {
  const [projetos, setProjetos] = useState([]);
  const [userId, setUserId] = useState(null);
  const [date, setDate] = useState(""); // Estado para a data
  const [userInfo, setUserInfo] = useState({ name: '', email: '' }); // Estado para informações do usuário

  useEffect(() => {
    const fetchUserId = () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        setUserId(decodedToken.id);
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (userId) {
        try {
          const userData = await getUserById(userId);
          setUserInfo({ name: userData.nome, email: userData.email });
        } catch (error) {
          console.error('Erro ao obter informações do usuário:', error);
        }
      }
    };

    const fetchProjetos = async () => {
      if (userId) {
        try {
          const response = await getUserPdfListProjetos(userId);
          setProjetos(Array.isArray(response) ? response : []);
        } catch (error) {
          console.error('Erro ao buscar Projetos:', error);
          setProjetos([]);
        }
      }
    };

    fetchUserInfo();
    fetchProjetos();
  }, [userId]);

  const handleDownload = (pdfData) => {
    try {
      const blob = new Blob([new Uint8Array(pdfData.pdfData.data)], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = pdfData.pdfName || 'document.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao baixar o PDF:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!date) {
      alert("Por favor, selecione um arquivo e uma data.");
      return;
    }

    const formattedDate = new Date(date).toLocaleDateString('pt-BR');
    const mensagem = `Gostaria de um novo projeto.\nPodemos fazer uma reunião na data: ${formattedDate}?`;
    const templateParams = {
      from_name: userInfo.name,
      reply_to: userInfo.email,
      subject: 'Solicitação de nova reunião',
      message: mensagem,
    };

    emailjs.send('service_mv4kbji', 'template_yvrd8nt', templateParams, 'U7y7EdIM0B_eUGFKQ')
      .then((response) => {
        console.log('E-mail enviado com sucesso:', response);
        alert('Reunião agendada com sucesso!');
        setDate("");
      })
      .catch((error) => {
        console.error('Erro ao enviar o e-mail:', error);
        alert('Erro ao agendar a reunião. Tente novamente.');
      });
  };

  return (
    <Layout>
      <div className="containerHome logo-container">
        <div>
          <img src={logo} alt="Logo" className="logo-home" />
        </div>
        <NavLink to="/Perfil" className="profileIcon">
          <div className="profileIconWrapper">
            <FaUser size={30} />
          </div>
        </NavLink>
      </div>

      <div className="container-projetos-geral">
        <div className="projetos-container">
          <h2>Projetos</h2>
          <div className="projetos-group">
            <div className="projetos-existentes">
              <ul className="projetos-lista">
                {projetos.length > 0 ? (
                  projetos.map((pdf, index) => (
                    <li className="projetos-item" key={index}>
                      <button onClick={() => handleDownload(pdf)}>
                        <FaFile className="icon-download" />
                        <div className="pdf-nome">{pdf.pdfName}</div>
                      </button>
                    </li>
                  ))
                ) : (
                  <li>Nenhum projeto disponível</li>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="novo-projeto-container">
          <h2>Novo projeto</h2>
          <form onSubmit={handleSubmit} className="novo-projeto-form">
            <div className="data-projetos">
              <div className="input-container">
                <FaCalendarAlt className="calendar-icon" />
                <input
                  type="date"
                  id="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
            </div>
            <button className="button-projetos" type="submit">Agendar reunião</button>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default Projetos;
