// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.relatorio-list {
    font-family: sans-serif;
}

.relatorio-list ul {
    list-style-type: none;
    padding: 0;
}

.relatorio-list li {
    margin-bottom: 10px;
}

li .pdf-nome {
    color: #ffffff;
}

li button {
    background: none;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

li button:focus {
    outline: none;
}

.icon-download {
    color: white;
    margin-left: 10px;
    font-size: 14px;
}

.relatorio-anexado-container {
    font-size: 18px;
    color: #52d88a;
}

.icon-delete {
    color: red;
    margin-left: 10px;
    cursor: pointer;
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/General/Relatorios/RelatorioList.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,eAAe;IACf,eAAe;AACnB","sourcesContent":[".relatorio-list {\n    font-family: sans-serif;\n}\n\n.relatorio-list ul {\n    list-style-type: none;\n    padding: 0;\n}\n\n.relatorio-list li {\n    margin-bottom: 10px;\n}\n\nli .pdf-nome {\n    color: #ffffff;\n}\n\nli button {\n    background: none;\n    border: none;\n    padding: 10px 15px;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 14px;\n}\n\nli button:focus {\n    outline: none;\n}\n\n.icon-download {\n    color: white;\n    margin-left: 10px;\n    font-size: 14px;\n}\n\n.relatorio-anexado-container {\n    font-size: 18px;\n    color: #52d88a;\n}\n\n.icon-delete {\n    color: red;\n    margin-left: 10px;\n    cursor: pointer;\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
