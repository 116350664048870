import React, { useState, useEffect } from 'react';
import { getUserPdfListMateriais, deletePdfMateriais } from '../../../apiService';
import { FaFile, FaTrash } from 'react-icons/fa';
import './MateriaisList.css';

const MateriaisList = ({ userId }) => {
    const [pdfs, setPdfs] = useState([]);

    useEffect(() => {
        const fetchPdfList = async () => {
            try {
                setPdfs([]);
                
                const response = await getUserPdfListMateriais(userId);
                
                if (response && Array.isArray(response)) {
                    setPdfs(response);
                } else {
                    setPdfs([]);
                }
            } catch (error) {
                console.error('Erro ao buscar lista de PDFs:', error);
            }
        };

        if (userId) {
            fetchPdfList();
        }
    }, [userId]);

    const handleDownload = (pdfData) => {
        try {
            const blob = new Blob([new Uint8Array(pdfData.pdfData.data)], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = pdfData.pdfName || 'document.pdf';
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Erro ao baixar o PDF:', error);
        }
    };

    const handleDelete = async (materiaisPdf_id) => {
        try {
            await deletePdfMateriais(materiaisPdf_id);
            alert('PDF deletado com sucesso.');
            setPdfs(pdfs.filter(pdf => pdf.materiaisPdf_id !== materiaisPdf_id)); // Atualiza a lista de PDFs
        } catch (error) {
            console.error('Erro ao deletar o PDF:', error);
        }
    };

    return (
        <div className="materiais-list">
            <ul>
                {pdfs.length > 0 ? (
                    pdfs.map((pdf, index) => (
                        <li key={index}>
                            <span className="pdf-label materiais-anexado-container">Material anexado:</span>
                            <button onClick={() => handleDownload(pdf)}>
                                <FaFile className="icon-download" />
                                <span className="pdf-nome"> {pdf.pdfName}</span>
                            </button>
                            <FaTrash 
                                className="icon-delete"
                                onClick={() => handleDelete(pdf.materiaisPdf_id)} // Use o campo correto para deletar
                            />
                        </li>
                    ))
                ) : (
                    <li className='materiais-anexado-container'>Nenhum material disponível</li>
                )}
            </ul>
        </div>
    );
};

export default MateriaisList;
