import React, { useState, useEffect } from 'react';
import { FaTrash } from 'react-icons/fa';
import '../Admin.css';
import { getGraficoAnual, addGraficoAnual, deleteGraficoAnual } from '../../apiService.js';

function Anual() {
    const [mes, setMes] = useState('');
    const [percentual, setPercentual] = useState('');
    const [ano, setAno] = useState('');
    const [rendimentos, setRendimentos] = useState([]);

    // Função para buscar os rendimentos cadastrados
    useEffect(() => {
        const fetchRendimentos = async () => {
            try {
                const response = await getGraficoAnual(); // Supondo que você tenha esta função definida para buscar os rendimentos
                setRendimentos(response);
            } catch (error) {
                console.error('Erro ao buscar rendimentos:', error);
            }
        };

        fetchRendimentos();
    }, []);

    // Função para adicionar um novo rendimento
    const handleAddRendimento = async (event) => {
        event.preventDefault();

        // Validar os valores
        if (!mes || isNaN(parseFloat(percentual)) || isNaN(parseInt(ano))) {
            alert('Por favor, preencha todos os campos corretamente.');
            return;
        }

        try {
            // Chamar a função de API para adicionar o rendimento
            await addGraficoAnual({ mes, percentual, ano }); // Supondo que essa função está definida
            alert('Rendimento adicionado com sucesso!');
            setMes('');
            setPercentual('');
            setAno('');

            // Atualizar a lista de rendimentos
            const updatedRendimentos = await getGraficoAnual();
            setRendimentos(updatedRendimentos);
        } catch (error) {
            console.error('Erro ao adicionar rendimento:', error);
            alert('Erro ao adicionar rendimento.');
        }
    };

    // Função para deletar um rendimento
    const handleDeleteRendimento = async (graficoAnual_id) => {
        try {
            await deleteGraficoAnual(graficoAnual_id); // Supondo que essa função está definida
            alert('Rendimento removido com sucesso!');
            
            // Atualizar a lista de rendimentos após a exclusão
            const updatedRendimentos = await getGraficoAnual();
            setRendimentos(updatedRendimentos);
        } catch (error) {
            console.error('Erro ao deletar rendimento:', error);
            alert('Erro ao deletar rendimento.');
        }
    };

    return (
        <div className="chart-content">
            <div className="add-rendimento">
                <form className="rendimento-form" onSubmit={handleAddRendimento}>
                    <div className="form-group">
                        <label htmlFor="mes">Mês:</label>
                        <select
                            id="mes"
                            value={mes}
                            onChange={(e) => setMes(e.target.value)}
                        >
                            <option value="">Selecione o mês</option>
                            {['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'].map((month) => (
                                <option key={month} value={month}>{month}</option>
                            ))}
                        </select>
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="porcentagem">Porcentagem:</label>
                        <input
                            type="number"
                            id="porcentagem"
                            step="0.1"
                            value={percentual}
                            onChange={(e) => setPercentual(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="ano">Ano:</label>
                        <input
                            type="text"
                            id="ano"
                            value={ano}
                            onChange={(e) => setAno(e.target.value)}
                        />
                    </div>

                    <button type="submit" className="save-button">Adicionar Rendimento</button>
                </form>

                <div className="relatorio-list">
                    <ul>
                        {rendimentos.length > 0 ? (
                            rendimentos.map((rendimento) => (
                                <li key={rendimento.graficoAnual_id} className="rendimento-item">
                                    <div className="rendimento-info">
                                        <span className="rendimento-mes">Mês: {rendimento.mes}</span>
                                        <span className="rendimento-percentual">Porcentagem: {rendimento.percentual}%</span>
                                        <span className="rendimento-ano">Ano: {rendimento.ano}</span>
                                        <FaTrash
                                            className="icon-delete"
                                            onClick={() => handleDeleteRendimento(rendimento.graficoAnual_id)}
                                            title="Excluir rendimento"
                                        />
                                    </div>
                                </li>
                            ))
                        ) : (
                            <li>Nenhum rendimento cadastrado.</li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Anual;
