import React, { useState } from 'react';
import './Login.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { login } from '../apiService'; 

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleClickLogin = async (event) => {
        event.preventDefault();

        try {
            const result = await login(email, password); // Utilize a função de login do apiService

            console.log(result);

            if (result.message === 'Conectado') {
                localStorage.setItem('token', result.token); // Armazenar id_usuario no localStorage
                navigate('/Home');
            } else {
                alert('Credenciais inválidas');
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
            alert('Falha na requisição');
            console.log(error);
        }
    };

    return (
        <div className="login-container">
            <img src="assets/logo.png" alt="Logo" className="logoIcon" />
            <h2>Log In</h2>
            <form onSubmit={handleClickLogin}>
                <div className="login-form">
                    <input
                        type="text"
                        name="email"
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="login-form">
                    <input
                        className='botaoForms'
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button className='show-password-button' type="button" onClick={togglePasswordVisibility}>
                        {showPassword ? <FaEyeSlash color="#3C1F5B" /> : <FaEye color="#3C1F5B" />}
                    </button>
                </div>
                <div>
                    <button className='botaoContainer' type="submit">Entrar</button>
                </div>
            </form>
        </div>
    );
}

export default Login;
