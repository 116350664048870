// src/components/Avisos/AvisosSection.js
import React, { useState, useEffect } from 'react';
import { addAviso, getAvisos, deleteAviso } from '../../../apiService'; // Ajuste o caminho conforme necessário
import { FaTrash } from 'react-icons/fa';
import './AvisosSection.css';
import '../General.css';

const AvisosSection = () => {
    const [avisos, setAvisos] = useState([]);
    const [novoAviso, setNovoAviso] = useState('');

    useEffect(() => {
        const fetchAvisos = async () => {
            try {
                const response = await getAvisos(); // Chame a API para buscar avisos existentes
                setAvisos(response); // Atualize o estado com os avisos
            } catch (error) {
                console.error('Erro ao buscar avisos:', error);
            }
        };

        fetchAvisos();
    }, []);

    const handleAddAviso = async (event) => {
        event.preventDefault();

        if (!novoAviso.trim()) {
            alert('Por favor, insira um aviso.');
            return;
        }

        try {
            const avisoData = { aviso: novoAviso }; // Certifique-se de que está enviando os dados no formato correto
            await addAviso(avisoData);
            alert('Aviso adicionado com sucesso.');
            setNovoAviso('');
            // Atualiza a lista de avisos
            const response = await getAvisos();
            setAvisos(response);
        } catch (error) {
            console.error('Erro ao adicionar aviso:', error);
            alert('Erro ao adicionar aviso.');
        }
    };

    const handleDeleteAviso = async (aviso_id) => {
        try {
            await deleteAviso(aviso_id);
            alert('Aviso deletado com sucesso.');
            setAvisos(avisos.filter(aviso => aviso.aviso_id !== aviso_id));
        } catch (error) {
            console.error('Erro ao deletar aviso:', error);
            alert('Erro ao deletar aviso.');
        }
    };

    return (
        <div className="avisos-admin-section">
            <h2>Adicionar Aviso</h2>
            <form onSubmit={handleAddAviso}>
                <input
                    type="text"
                    value={novoAviso}
                    onChange={(e) => setNovoAviso(e.target.value)}
                    placeholder="Digite um novo aviso"
                />
                <button type="submit">Adicionar Aviso</button>
                </form>
            <div className="avisos-list">
                <h3>Avisos existentes:</h3>
                <ul>
                    {Array.isArray(avisos) && avisos.length > 0 ? (
                        avisos.map((aviso, index) => (
                            <li key={index}>
                                {aviso.aviso}
                                <FaTrash 
                                    className="icon-delete"
                                    onClick={() => handleDeleteAviso(aviso.aviso_id)} // Use o campo correto para deletar
                                />
                            </li>
                        ))
                    ) : (
                        <li>Nenhum aviso disponível</li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default AvisosSection;
