import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaPen } from 'react-icons/fa';
import Modal from 'react-modal';
import emailjs from 'emailjs-com';
import './Dados.css';
import { getUserById } from '../../apiService'; // Importe a função da API

// Definir o elemento da aplicação
Modal.setAppElement('#root');

function decodificarTokenJWT() {
    const token = localStorage.getItem('token'); // Pegue o token do localStorage
    if (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload).id; // Retorne o userId
    }
    return null;
}

function Dados() {
    const [usuario, setUsuario] = useState({}); // Estado para armazenar as informações do usuário
    const [modalIsOpen, setModalIsOpen] = useState(false); // Estado para controlar o modal
    const [checkedFields, setCheckedFields] = useState({
        nome: false,
        cpf: false,
        email: false,
        telefone: false,
        ocupacao: false,
        estadoCivil: false,
        endereco: false,
    });

    useEffect(() => {
        // Função para buscar informações do usuário pelo userId
        const buscarInformacoesUsuario = async () => {
            const userId = decodificarTokenJWT();
            if (userId) {
                try {
                    const userData = await getUserById(userId);
                    setUsuario(userData); // Define todas as informações do usuário
                } catch (error) {
                    console.error('Erro ao obter informações do usuário:', error);
                }
            }
        };

        buscarInformacoesUsuario();
    }, []);

    // Função para abrir o modal
    const abrirModal = () => {
        setModalIsOpen(true);
    };

    // Função para fechar o modal
    const fecharModal = () => {
        setModalIsOpen(false);
    };

    // Função para lidar com a mudança dos checkboxes
    const handleCheckboxChange = (e) => {
        setCheckedFields({ ...checkedFields, [e.target.name]: e.target.checked });
    };

    // Função para enviar o email
    const enviarEmail = (event) => {
        event.preventDefault();
        
        const camposMarcados = Object.keys(checkedFields).filter(key => checkedFields[key]);
        if (camposMarcados.length === 0) {
            alert('Por favor, selecione pelo menos um campo para edição.');
            return;
        }

        const templateParams = {
            reply_to: usuario.email, // Você pode modificar isso para o email desejado
            from_name: usuario.nome,
            subject: 'Solicitação de Edição de Dados',
            message: `Solicitação de edição dos seguintes dados: ${camposMarcados.join(', ')}`
        };

        emailjs.send('service_mv4kbji', 'template_yvrd8nt', templateParams, 'U7y7EdIM0B_eUGFKQ')
            .then((response) => {
                alert('Solicitação enviada com sucesso!');
                fecharModal();
            }, (error) => {
                console.error('Erro ao enviar email:', error);
                alert('Erro ao enviar solicitação!');
            });
    };

    return (
        <div className="dados-container">
            <div className='nome-container-dados'>
            <Link to="/Perfil" className="back-link-dados">
                <FaArrowLeft className="back-icon" />
            </Link>
                <h1>{usuario.nome ? usuario.nome : 'Carregando...'}</h1>
            </div>
            <div className="dados-content">
                <div className='dados-infos'>
                    <p><strong>Nome:</strong> {usuario.nome || 'Carregando...'}</p>
                    <p><strong>CPF:</strong> {usuario.cpf || 'Carregando...'}</p>
                    <p><strong>Email:</strong> {usuario.email || 'Carregando...'}</p>
                    <p><strong>Telefone:</strong> {usuario.telefone || 'Carregando...'}</p>
                    <p><strong>Ocupação:</strong> {usuario.ocupacao || 'Carregando...'}</p>
                    <p><strong>Estado civil:</strong> {usuario.estadoCivil || 'Carregando...'}</p>
                    <p><strong>Endereço:</strong> {usuario.endereco || 'Carregando...'}</p>
                </div>
            </div>
            <div className='dados-botao'>
                    <button className="solicitar-edicao-btn" onClick={abrirModal}>
                        <FaPen className="icon-pen" /> SOLICITAR EDIÇÃO DOS DADOS
                    </button>
                </div>

            
            {/* Modal */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={fecharModal}
                contentLabel="Solicitação de Edição"
                className="modal"
                overlayClassName="overlay"
            >
                <h2>Solicitação de Edição</h2>
                <form onSubmit={enviarEmail}>
                    <div className="checkbox-container">
                        {Object.keys(checkedFields).map(field => (
                            <label key={field}>
                                <input
                                    type="checkbox"
                                    name={field}
                                    checked={checkedFields[field]}
                                    onChange={handleCheckboxChange}
                                />
                                {field.charAt(0).toUpperCase() + field.slice(1)}
                            </label>
                        ))}
                    </div>
                    <button type="submit">Enviar Solicitação</button>
                    <button type="button" onClick={fecharModal}>Cancelar</button>
                </form>
            </Modal>
        </div>
    );
}

export default Dados;
