import React from 'react';
import './Carteiras.css';
import Layout from '../Components/Layout.js';
import { NavLink } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import logo from "../Components/assets/logo.png";
import Anual from "./Gráficos/graficoAnual.js";
import Trimestral from "./Gráficos/graficoTrimestral.js";

function Carteiras() {

    return (
        <Layout>
            <div className="containerHome logo-container">
                <div>
                    <img src={logo} alt="Logo" className="logo-home" />
                </div>
                <NavLink to="/Perfil" className="profileIcon">
                    <div className="profileIconWrapper">
                        <FaUser size={30} />
                    </div>
                </NavLink>
            </div>
            <div className='carteiras-container'>
            <div className='chart-container-trimestral'>
                <NavLink to="/Carteiras/Trimestral">
                        <h2 className="clickable-title">GRÁFICO TRIMESTRAL</h2>
                    </NavLink>
                    <div className='chart-container-carteiras custom-font'>
                        <Trimestral />
                    </div>
                </div>

                <div className='chart-container-anual'>
                <NavLink to="/Carteiras/Anual">
                        <h2 className="clickable-title">GRÁFICO ANUAL</h2>
                    </NavLink>
                    <div className='chart-container-carteiras custom-font'>
                        <Anual />
                    </div>
                </div>
            </div>
        </Layout>
    );

}

export default Carteiras;