import React, { useEffect, useState } from 'react';
import './Trimestral.css'; // O arquivo CSS já está sendo importado
import Trimestral from "../Gráficos/graficoTrimestral";
import { Link, NavLink } from "react-router-dom";
import { FaArrowLeft, FaUser, FaFile } from "react-icons/fa";
import { getAtivosEmOperacao, getUserPdfListRelatorios, getGraficoTrimestral } from '../../apiService';

function GraficoTrimestral() {
    const [ativos, setAtivos] = useState([]);
    const [relatorios, setRelatorios] = useState([]);
    const [userId, setUserId] = useState(null);
    const [mediaRendimentos, setMediaRendimentos] = useState(0);

    useEffect(() => {
        // Chamada à API para buscar os ativos em operação
        const fetchAtivos = async () => {
            try {
                const response = await getAtivosEmOperacao();
                // Filtra os ativos onde tipoCarteira é igual a 'trimestral'
                const ativosTrimestrais = response.filter(ativo => ativo.tipoCarteira === 'Trimestral');
                setAtivos(ativosTrimestrais); // Armazena os dados dos ativos filtrados
            } catch (error) {
                console.error('Erro ao buscar os ativos:', error);
            }
        };

        fetchAtivos();
    }, []);

    useEffect(() => {
        const fetchUserId = () => {
            const token = localStorage.getItem('token');
            if (token) {
                const decodedToken = JSON.parse(atob(token.split('.')[1]));
                setUserId(decodedToken.id);
            }
        };

        fetchUserId();
    }, []);

    useEffect(() => {
        const fetchRelatorios = async () => {
            if (userId) {
                try {
                    const response = await getUserPdfListRelatorios(userId);
                    setRelatorios(Array.isArray(response) ? response : []);
                } catch (error) {
                    console.error('Erro ao buscar relatórios:', error);
                    setRelatorios([]);
                }
            }
        };

        fetchRelatorios();
    }, [userId]);

    useEffect(() => {
        const fetchGraficoTrimestral = async () => {
            try {
                const response = await getGraficoTrimestral();
                if (Array.isArray(response) && response.length > 0) {
                    const total = response.reduce((sum, item) => {
                        // Converte o valor de porcentagem para número e adiciona ao total
                        const porcentagem = parseFloat(item.percentual) || 0;
                        return sum + porcentagem;
                    }, 0);
                    const media = total / response.length;
                    setMediaRendimentos(media.toFixed(2)); // Calcula e formata a média
                }
            } catch (error) {
                console.error('Erro ao buscar dados do gráfico:', error);
            }
        };

        fetchGraficoTrimestral();
    }, []);

    const handleDownload = (pdfData) => {
        try {
            const blob = new Blob([new Uint8Array(pdfData.pdfData.data)], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = pdfData.pdfName || 'document.pdf';
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Erro ao baixar o PDF:', error);
        }
    };

    return (
        <div className="trimestral-container">
            <Link to="/Carteiras" className="back-link">
                <FaArrowLeft className="back-icon" />
            </Link>

            <NavLink to="/Perfil" className="profile-icon-trimestral">
                <div className="profileIconWrapper">
                    <FaUser size={30} />
                </div>
            </NavLink>

            <h1 className="titulo-trimestral">CARTEIRA TRIMESTRAL</h1>
            <p className="subtitulo-rendimento">
                Rendimento: {mediaRendimentos && `${mediaRendimentos}%`}
            </p>
            <div className="grafico-trimestral">
                <Trimestral />
            </div>

            <div className="ativos-operacao-container">
                <h2 className="ativos-operacao-titulo">Ativos em operação</h2>
                <ul className="ativos-operacao-lista">
                    {ativos.length > 0 ? (
                        ativos.map((ativo, index) => (
                            <li
                                key={index}
                                className={`ativos-operacao-item ${ativo.porcentagem >= 0 ? 'ativo-positivo' : 'ativo-negativo'}`}
                            >
                                {ativo.ativo} - {ativo.porcentagem}%
                            </li>
                        ))
                    ) : (
                        <li>Nenhum ativo encontrado</li>
                    )}
                </ul>
            </div>

            <div className="relatorios-container">
                <h2 className="relatorios-titulo">RELATÓRIOS</h2>
                <ul className="relatorios-lista">
                    {relatorios.length > 0 ? (
                        relatorios.map((pdf, index) => (
                            <li className="relatorios-item" key={index}>
                                <button onClick={() => handleDownload(pdf)}>
                                    <FaFile className="icon-download" />
                                    <div className="pdf-nome">{pdf.pdfName}</div>
                                </button>
                            </li>
                        ))
                    ) : (
                        <li>Nenhum relatório disponível</li>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default GraficoTrimestral;
