import React, { useState, useEffect } from "react";
import "./Avisos.css";
import Layout from "../Components/Layout.js";
import { NavLink } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import logo from "../Components/assets/logo.png";
import { getAvisos, getUserPdfListMateriais } from "../apiService"; // Função para buscar avisos e materiais
import { FaFile } from 'react-icons/fa';

const Avisos = () => {
  const [avisos, setAvisos] = useState([]);
  const [materiais, setMateriais] = useState([]);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchAvisos = async () => {
      try {
        const response = await getAvisos();
        setAvisos(Array.isArray(response) ? response : []);
      } catch (error) {
        console.error('Erro ao buscar avisos:', error);
        setAvisos([]);
      }
    };

    fetchAvisos();
  }, []);

  useEffect(() => {
    const fetchUserId = () => {
      const token = localStorage.getItem('token'); // Ajuste o nome conforme necessário
      if (token) {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        setUserId(decodedToken.id);
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    const fetchMateriais = async () => {
      if (userId) {
        try {
          const response = await getUserPdfListMateriais(userId);
          setMateriais(Array.isArray(response) ? response : []);
        } catch (error) {
          console.error('Erro ao buscar materiais:', error);
          setMateriais([]);
        }
      }
    };

    fetchMateriais();
  }, [userId]);

  const handleDownload = (pdfData) => {
    try {
      const blob = new Blob([new Uint8Array(pdfData.pdfData.data)], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = pdfData.pdfName || 'document.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao baixar o PDF:', error);
    }
  };

  return (
    <Layout>
      <div className="containerHome logo-container">
        <div>
          <img src={logo} alt="Logo" className="logo-home" />
        </div>
        <NavLink to="/Perfil" className="profileIcon">
          <div className="profileIconWrapper">
            <FaUser size={30} />
          </div>
        </NavLink>
      </div>

      <div className="container-avisos-geral">
        <div className="avisos-container">
          <h2>Avisos</h2>
          <div className="avisos-existentes">
            <ul className="aviso-lista">
              {avisos.length > 0 ? (
                avisos.map((aviso) => (
                  <li className="aviso-item" key={aviso.id}>
                    {aviso.aviso} {/* Exibindo apenas o campo 'aviso' */}
                  </li>
                ))
              ) : (
                <li>Nenhum aviso encontrado</li> // Mostra mensagem quando não houver avisos
              )}
            </ul>
          </div>
        </div>

        <div className="material-container">
          <h2>Materiais</h2>
          <div className="materiais-group">
            <div className="materiais-existentes">
              <ul className="materiais-lista">
                {materiais.length > 0 ? (
                  materiais.map((pdf, index) => (
                    <li className="materiais-item" key={index}>
                      <button onClick={() => handleDownload(pdf)}>
                        <FaFile className="icon-download" />
                        <div className="pdf-nome">{pdf.pdfName}</div>
                      </button>
                    </li>
                  ))
                ) : (
                  <li>Nenhum material disponível</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Avisos;
