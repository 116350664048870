import React, { useState, useEffect } from 'react';
import { FaUser, FaChartBar, FaCog } from 'react-icons/fa';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import './Admin.css';
import MobileWarningPopup from './MobileWarningPopup.js';
import Users from './Users';
import Charts from './Graficos/Charts';
import General from './General/General';

function Admin() {
    const [selectedOption, setSelectedOption] = useState('users');
    const [showMobileWarning, setShowMobileWarning] = useState(false);

    const handleCloseMobileWarning = () => {
        setShowMobileWarning(false);
    };

    useEffect(() => {
        const isMobile = window.innerWidth <= 600;
        if (isMobile) {
            setShowMobileWarning(true);
        }
    }, []);

    return (
        <div className="admin-panel">
            {showMobileWarning && <MobileWarningPopup onClose={handleCloseMobileWarning} />}
            <NavLink to="/Home" className="botaoMenu">
                <div className="botaoMenu-content">
                    <MdKeyboardArrowLeft size={20} /> SAIR DO PAINEL ADMIN
                </div>
            </NavLink>
            <div className="admin-menu">
                <button className={selectedOption === 'users' ? 'selected' : ''} onClick={() => setSelectedOption('users')}>
                    <FaUser className="icon" /> Usuários
                </button>
                <button className={selectedOption === 'charts' ? 'selected' : ''} onClick={() => setSelectedOption('charts')}>
                    <FaChartBar className="icon" /> Gráficos
                </button>
                <button className={selectedOption === 'general' ? 'selected' : ''} onClick={() => setSelectedOption('general')}>
                    <FaCog className="icon" /> Geral
                </button>
            </div>
            <div className="admin-content">

                <div className="content-wrapper">
                    {selectedOption === 'users' && <Users />}
                    {selectedOption === 'charts' && <Charts />}
                    {selectedOption === 'general' && <General />}
                </div>
            </div>
        </div>

    );
}

export default Admin;