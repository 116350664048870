const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


// Função para obter o token JWT do localStorage
const getToken = () => {
    return localStorage.getItem('token');
};

export const getAllUsers = async () => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar usuários');
        }
        return response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getUserById = async (userId) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar dados do usuário');
        }
        return response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const updateUser = async (userId, userData) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/${userId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(userData)
        });
        if (!response.ok) {
            throw new Error('Erro ao atualizar dados do usuário');
        }
        return await response.json(); // Certifique-se de que está retornando JSON
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const deleteUser = async (userId) => {
    try {
        const token = getToken();
        console.log("userId enviado:", userId);
        const response = await fetch(`${API_BASE_URL}/users/${userId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};

export const getUserInvestments = async (userId) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/investimentos/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar investimentos do usuário');
        }
        return response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const updateUserInvestments = async (userId, investmentsData) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/investimentos/${userId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(investmentsData)
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};

export const login = async (email, password) => {
    try {
        const response = await fetch(`${API_BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        });

        if (!response.ok) {
            throw new Error('Erro na solicitação');
        }

        return response.json();
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error;
    }
};

export const createUser = async (userData) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(userData)
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};

export const getRendimentosMensais = async (userId) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/investimentos/geral/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar rendimentos mensais');
        }
        return response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};


// Adicionar rendimento mensal
 export const addRendimentoMensal = async (rendimentoData, userId) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/investimentos/geral/${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            
            body: JSON.stringify(rendimentoData)
            
        });
        if (!response.ok) {
            throw new Error('Erro ao adicionar rendimento mensal');
        }

        return response.json();
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};


export const deleteRendimentoMensal = async (investimento_id) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/investimentos/geral/${investimento_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};

export const getGraficoInicial = async (userId) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/graficos/inicial/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar rendimentos mensais');
        }
        return response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};


// apiService.js

export const addGraficoInicial = async (rendimentoData, userId) => {
    try {
        // Verificar se todos os campos necessários estão presentes
        const { ano, mes, percentual } = rendimentoData;
        if (!ano || !mes || percentual === undefined) {
            console.error('Dados incompletos fornecidos:', rendimentoData); // Adicione este log
            throw new Error('Dados incompletos fornecidos');
        }

        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/graficos/inicial/${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(rendimentoData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Erro ao adicionar gráfico inicial: ${errorText}`);
        }

        return response.json();
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};




export const deleteGraficoInicial = async (graficoInicial_id) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/graficos/inicial/${graficoInicial_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};

export const getGraficoAnual = async () => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/graficos/anual`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar rendimentos mensais');
        }
        return response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const addGraficoAnual = async (rendimentoData) => {
    try {
        // Verificar se todos os campos necessários estão presentes
        const { ano, mes, percentual } = rendimentoData;
        if (!ano || !mes || percentual === undefined) {
            console.error('Dados incompletos fornecidos:', rendimentoData); // Adicione este log
            throw new Error('Dados incompletos fornecidos');
        }

        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/graficos/anual`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(rendimentoData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Erro ao adicionar gráfico inicial: ${errorText}`);
        }

        return response.json();
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};


export const deleteGraficoAnual = async (graficoAnual_id) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/graficos/anual/${graficoAnual_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};

export const getGraficoTrimestral = async () => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/graficos/trimestral`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar rendimentos mensais');
        }
        console.log(response)
        return response.json();

    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const addGraficoTrimestral = async (rendimentoData) => {
    try {
        // Verificar se todos os campos necessários estão presentes
        const { ano, mes, percentual } = rendimentoData;
        if (!ano || !mes || percentual === undefined) {
            console.error('Dados incompletos fornecidos:', rendimentoData); // Adicione este log
            throw new Error('Dados incompletos fornecidos');
        }

        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/graficos/trimestral`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(rendimentoData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Erro ao adicionar gráfico inicial: ${errorText}`);
        }

        return response.json();
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};


export const deleteGraficoTrimestral = async (graficoAnual_id) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/graficos/trimestral/${graficoAnual_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};

export const getTemporadaAportes = async () => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/temporada/aportes`, {
            headers: {
                method: 'GET',
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar temporadaAportes');
        }
        return response.json();
    } catch (error) {
        console.error('Erro ao obter temporadaAportes:', error);
        throw error;
    }
};

export const updateTemporadaAportes = async (status) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/temporada/aportes`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ temporadaAportes: status ? 1 : 0 })
        });

        if (!response.ok) {
            throw new Error('Erro ao atualizar temporadaAportes');
        }

        return response.json();
    } catch (error) {
        console.error('Erro ao atualizar temporadaAportes:', error);
        throw error;
    }
};

export const uploadPdfRelatorios = async (pdfFile, pdfName, userId) => {
    try {
        const token = getToken();
        const formData = new FormData();
        formData.append('pdf', pdfFile); // Adiciona o arquivo ao FormData
        formData.append('user_id', userId);
        formData.append('pdfName', pdfName); // Inclui o nome do arquivo

        const response = await fetch(`${API_BASE_URL}/users/upload/pdf/relatorios`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        });

        if (!response.ok) {
            throw new Error('Erro ao fazer upload do PDF');
        }

        return response.json();
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error;
    }
};

export const uploadPdfMateriais = async (pdfFile, pdfName, userId) => {
    try {
        const token = getToken();
        const formData = new FormData();
        formData.append('pdf', pdfFile); // Adiciona o arquivo ao FormData
        formData.append('user_id', userId);
        formData.append('pdfName', pdfName); // Inclui o nome do arquivo

        const response = await fetch(`${API_BASE_URL}/users/upload/pdf/materiais`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        });

        if (!response.ok) {
            throw new Error('Erro ao fazer upload do PDF');
        }

        return response.json();
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error;
    }
};

export const uploadPdfProjetos = async (pdfFile, pdfName, userId) => {
    try {
        const token = getToken();
        const formData = new FormData();
        formData.append('pdf', pdfFile); // Adiciona o arquivo ao FormData
        formData.append('user_id', userId);
        formData.append('pdfName', pdfName); // Inclui o nome do arquivo

        const response = await fetch(`${API_BASE_URL}/users/upload/pdf/projetos`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        });

        if (!response.ok) {
            throw new Error('Erro ao fazer upload do PDF');
        }

        return response.json();
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error;
    }
};

export const uploadPdfContratos = async (pdfFile, pdfName, userId) => {
    try {
        const token = getToken();
        const formData = new FormData();
        formData.append('pdf', pdfFile); // Adiciona o arquivo ao FormData
        formData.append('user_id', userId);
        formData.append('pdfName', pdfName); // Inclui o nome do arquivo

        const response = await fetch(`${API_BASE_URL}/users/upload/pdf/contratos`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        });

        if (!response.ok) {
            throw new Error('Erro ao fazer upload do PDF');
        }

        return response.json();
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error;
    }
};

export const getUserPdfListRelatorios = async (userId) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/pdfs/relatorios/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar lista de PDFs');
        }

        return response.json(); // A resposta deve ser uma lista de objetos com pdfName e pdfData
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error;
    }
};

export const getUserPdfListMateriais = async (userId) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/pdfs/materiais/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar lista de PDFs');
        }

        return response.json(); // A resposta deve ser uma lista de objetos com pdfName e pdfData
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error;
    }
};

export const getUserPdfListProjetos = async (userId) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/pdfs/projetos/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar lista de PDFs');
        }

        return response.json(); // A resposta deve ser uma lista de objetos com pdfName e pdfData
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error;
    }
};

export const getUserPdfListContratos = async (userId) => {
    try {
        const token = getToken();
        const response = await fetch(`${API_BASE_URL}/users/pdfs/contratos/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar lista de PDFs');
        }

        return response.json(); // A resposta deve ser uma lista de objetos com pdfName e pdfData
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error;
    }
};

// No seu apiService.js
export const deletePdfMateriais = async (pdf_id) => {
    try {
        const token = getToken(); // Assumindo que você tem uma função para obter o token
        const response = await fetch(`${API_BASE_URL}/users/pdfs/materiais/${pdf_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};

export const deletePdfRelatorios = async (pdf_id) => {
    try {
        const token = getToken(); // Assumindo que você tem uma função para obter o token
        const response = await fetch(`${API_BASE_URL}/users/pdfs/relatorios/${pdf_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};

export const deletePdfProjetos = async (pdf_id) => {
    try {
        const token = getToken(); // Assumindo que você tem uma função para obter o token
        const response = await fetch(`${API_BASE_URL}/users/pdfs/projetos/${pdf_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};

export const deletePdfContratos = async (pdf_id) => {
    try {
        const token = getToken(); // Assumindo que você tem uma função para obter o token
        const response = await fetch(`${API_BASE_URL}/users/pdfs/contratos/${pdf_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};

// Função para obter todos os avisos
export const getAvisos = async () => {
    try {
        const token = getToken(); // Obtém o token JWT do localStorage
        const response = await fetch(`${API_BASE_URL}/users/avisos/listar`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar avisos');
        }
        return await response.json(); // Retorna a lista de avisos em formato JSON
    } catch (error) {
        console.error('Erro ao buscar avisos:', error);
        throw error;
    }
};

// Função para adicionar um novo aviso
export const addAviso = async (aviso) => {
    try {
        const token = getToken(); // Obtém o token JWT do localStorage
        const response = await fetch(`${API_BASE_URL}/users/avisos`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(aviso) // Converte os dados do aviso para JSON e envia no corpo da solicitação
        });

        if (!response.ok) {
            throw new Error('Erro ao adicionar aviso');
        }

        return await response.json(); // Retorna a resposta do servidor em formato JSON
    } catch (error) {
        console.error('Erro ao adicionar aviso:', error);
        throw error;
    }
};

export const deleteAviso = async (aviso_id) => {
    try {
        const token = getToken(); // Assumindo que você tem uma função para obter o token
        const response = await fetch(`${API_BASE_URL}/users/avisos/${aviso_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};

// Função para obter todos os ativos
export const getAtivosEmOperacao = async () => {
    try {
        const token = getToken(); // Obtém o token JWT do localStorage
        const response = await fetch(`${API_BASE_URL}/users/ativos/listar`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar ativos');
        }
        return await response.json(); // Retorna a lista de ativos em formato JSON
    } catch (error) {
        console.error('Erro ao buscar ativos:', error);
        throw error;
    }
};

// Função para adicionar um novo aviso
export const addAtivo = async (aviso) => {
    try {
        const token = getToken(); // Obtém o token JWT do localStorage
        const response = await fetch(`${API_BASE_URL}/users/ativos`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(aviso) // Converte os dados do aviso para JSON e envia no corpo da solicitação
        });

        if (!response.ok) {
            throw new Error('Erro ao adicionar ativo');
        }

        return await response.json(); // Retorna a resposta do servidor em formato JSON
    } catch (error) {
        console.error('Erro ao adicionar ativo:', error);
        throw error;
    }
};

export const deleteAtivo = async (ativo_id) => {
    try {
        const token = getToken(); // Assumindo que você tem uma função para obter o token
        const response = await fetch(`${API_BASE_URL}/users/ativos/${ativo_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch {
            console.error('Resposta não é um JSON válido:', text);
            return { message: text };
        }
    } catch (error) {
        console.error('Erro na solicitação:', error);
        throw error;
    }
};