import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaUser, FaCog, FaFileAlt, FaSignOutAlt } from 'react-icons/fa'; // Importe o ícone de logout
import './Perfil.css';
import { getUserById, getUserPdfListContratos } from '../apiService';

function decodificarTokenJWT() {
    const token = localStorage.getItem('token');
    if (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload).id;
    }
    return null;
}

function Perfil() {
    const [usuario, setUsuario] = useState({});
    const [contratos, setContratos] = useState([]); // Estado para armazenar contratos
    const [menu, setMenu] = useState('dados'); // Estado para controlar qual menu exibir
    const navigate = useNavigate();

    useEffect(() => {
        const buscarInformacoesUsuario = async () => {
            const userId = decodificarTokenJWT();
            if (userId) {
                try {
                    const userData = await getUserById(userId);
                    setUsuario(userData);
                } catch (error) {
                    console.error('Erro ao obter informações do usuário:', error);
                }
            }
        };

        buscarInformacoesUsuario();
    }, []);

    useEffect(() => {
        const buscarContratos = async () => {
            const userId = decodificarTokenJWT();
            if (userId && menu === 'contratos') {
                try {
                    const contratosData = await getUserPdfListContratos(userId);
                    setContratos(contratosData);
                } catch (error) {
                    console.error('Erro ao obter contratos:', error);
                }
            }
        };

        buscarContratos();
    }, [menu]);

    const handleDownload = (pdfData) => {
        try {
            const blob = new Blob([new Uint8Array(pdfData.pdfData.data)], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = pdfData.pdfName || 'document.pdf';
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Erro ao baixar o PDF:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/Login');
    };

    return (
        <div className="perfil-container">
            <div className='nome-container-perfil'>
                <Link to="/Home" className="back-link-perfil">
                    <FaArrowLeft className="back-icon" />
                </Link>
                <h1>{usuario.nome ? usuario.nome : 'Carregando...'}</h1>
            </div>
            <div className="menu-buttons">
                <button 
                    className={`menu-btn ${menu === 'dados' ? 'active' : ''}`}
                    onClick={() => setMenu('dados')}
                >
                    <FaCog /> Suporte & Ações
                </button>
                <button 
                    className={`menu-btn ${menu === 'contratos' ? 'active' : ''}`}
                    onClick={() => setMenu('contratos')}
                >
                    <FaFileAlt /> Contratos
                </button>
            </div>
            <div className="perfil-content">
                <div className='perfil-infos'>
                    {menu === 'dados' && (
                        <Link to="/Perfil/Dados">
                            <button className="dados-perfil-btn">
                                <FaUser /> Dados do Perfil
                            </button>
                        </Link>
                    )}

                    {menu === 'dados' && (
                        <button className="logout-btn" onClick={handleLogout}>
                            <FaSignOutAlt /> Sair
                        </button>
                    )}

                    {menu === 'contratos' && (
                        <div className='perfil-contratos'>
                            {contratos.length > 0 ? (
                                <ul>
                                    {contratos.map((contrato, index) => (
                                        <li key={index}>
                                            <button onClick={() => handleDownload(contrato)} className='file-container-contrato'>
                                                <FaFileAlt />
                                                {contrato.pdfName}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>Você ainda não possui contratos.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Perfil;
