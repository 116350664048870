// HomePage.js
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaUser, FaUsersCog } from 'react-icons/fa';
import './HomePage.css';
import Layout from '../Components/Layout.js';
import logo from '../Components/assets/logo.png';
import GraficoHomePage from './Grafico/GraficoHomePage.js';
import VisaoGeral from './VisaoGeral.js';
import { getUserInvestments } from '../apiService.js'; // Verifique o caminho

function HomePage() {
    const token = localStorage.getItem('token');
    const [userInvestments, setUserInvestments] = useState({});
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const permissaoId = decodedToken.permissao_id;
    const id = decodedToken.id;

    useEffect(() => {
        async function fetchInvestments() {
            try {
                const investments = await getUserInvestments(id);
                setUserInvestments(investments);
            } catch (error) {
                console.error('Erro ao carregar os investimentos:', error);
            }
        }

        if (id) {
            fetchInvestments();
        }
    }, [id]);

    return (
        <Layout>
            <div className="containerHome logo-container">
                <div>
                    <img src={logo} alt="Logo" className="logo-home" />
                </div>
                <NavLink to="/Perfil" className="profileIcon-home">
                    <div className="profileIconWrapper">
                        <FaUser size={30} />
                    </div>
                </NavLink>
            </div>

            {(permissaoId === 1 || permissaoId === 3) && (
                <NavLink to="/Admin" style={{ textDecoration: 'none' }}>
                    <div className="containerHome button-container fonte-admin">
                        <button><FaUsersCog size={30} /> PAINEL ADMIN</button>
                    </div>
                </NavLink>
            )}

            <div className='capital-container'>
                <h2>Capital Total</h2>
                {userInvestments?.capitalTotal ? (
                    <h1>{userInvestments.capitalTotal}</h1>
                ) : (
                    <h1>R$-</h1>
                )}
            </div>

            <div className="visao-container">
                <h2>Visão Geral</h2>
                <div className='quadro-visao'>
                    <VisaoGeral />
                </div>
            </div>

            <div className='rendimentosContainer'>
                <h2>Rendimentos Gerais</h2>
            </div>

            <div className="chart-container containerHome custom-font">
                <GraficoHomePage id={id} />
            </div>
        </Layout>
    );
}

export default HomePage;
