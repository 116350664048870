// src/components/Grafico/GraficoHomePage.js
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { getGraficoAnual } from '../../apiService'; // Importe a função do apiService

const Anual = ({ id }) => {
    const [graficoData, setGraficoData] = useState({ meses: [], valores: [] });
    
    useEffect(() => {
        // Buscar rendimentos mensais
        getGraficoAnual(id)
            .then(rendimentos => {
                // Processar dados para o gráfico
                const meses = rendimentos.map(item => `${item.mes}/${item.ano}`);
                const valores = rendimentos.map(item => item.percentual);

                setGraficoData({ meses, valores });
            })
            .catch(error => {
                console.error('Erro ao buscar rendimentos:', error);
            });
    }, [id]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Dataset',
            },
        },
    };

    const data = {
        labels: graficoData.meses,
        datasets: [
            {
                label: 'Rendimentos',
                data: graficoData.valores,
                backgroundColor: '#00BF63',
                borderColor: 'green',
                borderWidth: 1
            }
        ],
    };

    return <Bar options={options} data={data} />;
}

export default Anual;
