/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import './CreateUserForm.css';
import { Link } from 'react-router-dom';
import { createUser, getAllUsers } from '../apiService'; // Importe a função de criar usuário

function CreateUserForm() {
    const [selectedUser, setSelectedUser] = useState({
        CPF: '',
        capitalInicial: '',
        modalidade: '',
        email: '',
        telefone: '',
        senha: '',
        estadoCivil: '',
        ocupacao: '',
        endereco: '',
        comentarioGeral: '',
        ativo: 0
    });

    const [userNames, setUserNames] = useState([]);
    const [permissaoId, setPermissaoId] = useState(null);
    const [userInvestments, setUserInvestments] = useState({});

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    
    useEffect(() => {
        const fetchUserNames = async () => {
            try {
                const data = await getAllUsers();
                setUserNames(data);
            } catch (error) {
                console.error('Erro ao buscar nomes de usuários:', error);
            }
        };

        fetchUserNames();

        const token = localStorage.getItem('token');
        const decodedToken = parseJwt(token);
        setPermissaoId(decodedToken?.permissao_id);
    }, []);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        console.log('Dados enviados:', selectedUser); // Adicione este log

        try {
            // Utilize a função de criar usuário do apiService
            const result = await createUser(selectedUser);

            console.log('Novo usuário criado com sucesso!', result);

            // Limpa os campos do formulário após a criação
            setSelectedUser({
                CPF: '',
                capitalInicial: '',
                modalidade: '',
                email: '',
                telefone: '',
                senha: '',
                estadoCivil: '',
                ocupacao: '',
                endereco: '',
                comentarioGeral: '',
                ativo: 0
            });

            // Exiba mensagem de sucesso
            alert('Novo usuário criado com sucesso!');
        } catch (error) {
            console.error('Erro ao criar novo usuário:', error);

            // Exiba a mensagem de erro da resposta
            const errorMessage = error.message || 'Falha ao criar novo usuário. Tente novamente.';
            alert(errorMessage);
        }
    };

    return (
        <div className="create-user-container">
            <h2>CRIAR NOVO USUÁRIO</h2>
            <form className="create-form" onSubmit={handleFormSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Nome:</label>
                    <input placeholder="Ex: Pedro" type="text" id="name" name="name" required value={selectedUser?.nome || ''} onChange={(e) => setSelectedUser({ ...selectedUser, nome: e.target.value })} />
                </div>
                <div className="form-group">
                    <label htmlFor="cpf">CPF:</label>
                    <input placeholder="Ex: 000.000.000-00" type="text" id="cpf" name="cpf" required value={selectedUser?.CPF || ''} onChange={(e) => setSelectedUser({ ...selectedUser, CPF: e.target.value })} />
                </div>
                <div className="form-group">
                    <label htmlFor="modalidade">Modalidade:</label>
                    <select id="modalidade" name="modalidade" required value={selectedUser?.modalidade || ''} onChange={(e) => setSelectedUser({ ...selectedUser, modalidade: e.target.value })}>
                        <option value="">Selecione uma modalidade</option>
                        <option value="Anual">Anual</option>
                        <option value="Trimestral">Trimestral</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input placeholder="Ex: teste@gmail.com" type="email" id="email" name="email" required value={selectedUser?.email || ''} onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })} />
                </div>
                <div className="form-group">
                    <label htmlFor="telefone">Telefone:</label>
                    <input placeholder="Ex: (00)90000-0000" type="text" id="telefone" name="telefone" required value={selectedUser?.telefone || ''} onChange={(e) => setSelectedUser({ ...selectedUser, telefone: e.target.value })} />
                </div>
                <div className="form-group" style={{ flexBasis: 'calc(50% - 10px)' }}>
                    <label htmlFor="senha">Senha:</label>
                    <input placeholder="Ex: ********" type="password" id="senha" name="senha" required value={selectedUser?.senha || ''} onChange={(e) => setSelectedUser({ ...selectedUser, senha: e.target.value })} />
                </div>
                <div className="form-group">
                    <label htmlFor="estadoCivil">Estado Civil:</label>
                    <select id="estadoCivil" name="estadoCivil" required value={selectedUser?.estadoCivil || ''} onChange={(e) => setSelectedUser({ ...selectedUser, estadoCivil: e.target.value })}>
                        <option value="">Selecione um estado civil</option>
                        <option value="Solteiro">Solteiro(a)</option>
                        <option value="Casado">Casado(a)</option>
                        <option value="Divorciado">Divorciado(a)</option>
                        <option value="Viuvo">Viúvo(a)</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="ocupacao">Ocupação:</label>
                    <input placeholder="Ex: Investidor" type="text" id="ocupacao" name="ocupacao" required value={selectedUser?.ocupacao || ''} onChange={(e) => setSelectedUser({ ...selectedUser, ocupacao: e.target.value })} />
                </div>
                <div className="form-group">
                    <label htmlFor="endereco">Endereço:</label>
                    <input placeholder="Digite aqui o endereço completo (Rua, Bairro, Cidade, UF e complemento)" type="text" id="endereco" name="endereco" required value={selectedUser?.endereco || ''} onChange={(e) => setSelectedUser({ ...selectedUser, endereco: e.target.value })} />
                </div>
                {permissaoId === 1 && (
                    <div className="form-group">
                        <label htmlFor="permissao">Permissão:</label>
                        <select id="permissao" name="permissao" required value={selectedUser?.permissao_id || ''} onChange={(e) => setSelectedUser({ ...selectedUser, permissao_id: e.target.value })}>
                            <option value="">Selecione uma permissão</option>
                            <option value="2">Usuário</option>
                            <option value="3">Administrador</option>
                        </select>
                    </div>
                )}
                <div className="form-group" style={{ flexBasis: 'calc(50% - 10px)' }}>
                    <label htmlFor="comentarios">Comentários Gerais:</label>
                    <textarea placeholder="Digite qualquer comentário geral que deseje." id="comentarios" name="comentarios" value={selectedUser?.comentarioGeral || ''} onChange={(e) => setSelectedUser({ ...selectedUser, comentarioGeral: e.target.value })}></textarea>
                </div>
                <div className="form-group slide-container" style={{ marginTop: '20px' }}>
                    <label htmlFor="ativo">Usuário Ativo?</label>
                    <div className="slide-container">
                        <input type="checkbox" id="ativo" name="ativo" checked={selectedUser?.ativo === 1} onChange={(e) => setSelectedUser({ ...selectedUser, ativo: e.target.checked ? 1 : 0 })} />
                        <label className="slide" htmlFor="ativo"></label>
                    </div>
                </div>
                <button className="create-save-button" type="submit">
                    <FaCheck className="icon" /> Salvar
                </button>
                <Link to="/Admin" className="back-button">Voltar</Link>
            </form>
        </div>
    );
}

export default CreateUserForm;
