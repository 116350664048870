import React from 'react';
import './Admin.css';

function MobileWarningPopup({ onClose }) {
    return (
        <div className="mobile-warning-popup">
            <p>Não recomendamos a atualização por aqui. Opte sempre por usar o navegador.</p>
            <button onClick={onClose}>Fechar</button>
        </div>
    );
}

export default MobileWarningPopup;
