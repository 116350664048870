// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-content h2 {
    color: white;
    font-family: sans-serif;
    position: relative;
}

.chart-content h2:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    /* Ajuste conforme necessário para a posição da linha */
    width: 100%;
    height: 2px;
    /* Espessura da linha */
    background-color: #9A52D8;
}

.add-rendimento-trimestral{
margin-top: 100px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Graficos/Charts.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,YAAY;IACZ,uDAAuD;IACvD,WAAW;IACX,WAAW;IACX,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;AACA,iBAAiB;AACjB","sourcesContent":[".chart-content h2 {\n    color: white;\n    font-family: sans-serif;\n    position: relative;\n}\n\n.chart-content h2:after {\n    content: '';\n    position: absolute;\n    left: 0;\n    bottom: -5px;\n    /* Ajuste conforme necessário para a posição da linha */\n    width: 100%;\n    height: 2px;\n    /* Espessura da linha */\n    background-color: #9A52D8;\n}\n\n.add-rendimento-trimestral{\nmargin-top: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
